import { Input, Button, extendTheme } from '@chakra-ui/react'
import banner from './assets/banner.png'
import './App.css';
import fail from './assets/fail.png'
import fake from './assets/fake.png'
import ok from './assets/ok.png'
import warning from './assets/warning.png'
import bannermob from './assets/banner-mob.png'
import { ChakraProvider } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { Icon, createIcon } from '@chakra-ui/react'
import adapter from 'webrtc-adapter';
import { Person4, QrCodeScanner, Stop } from "@mui/icons-material";
import { AppBar, Avatar, Box, CssBaseline, Fab, Toolbar, Typography } from "@mui/material";
import QrScanner from "qr-scanner";
let stopScan = false
let hasilScan = ''
function App() {
  const theme = extendTheme({
    colors: {
      brand: {
        50: "#a30707",
        100: "#a30707",
        500: "#a30707", // you need this
      }
    }
  });
  const [code, setCode] = useState('');

  const [codeurl, setCodeUrl] = useState('')
  const handleCode = (e) => setCode(e.target.value)
  const [payload, setPayload] = useState({ active: 0, code: '', fraud: 0, created_date: null, first_verify_date: null, last_verify_date: null, id: null, scanned: null, tipe: '' })
  const [refresh, setRef] = useState('')
  const [bdis, setBDis] = useState(false)
  const [data, setData] = useState('No result');
  const [btnScan, setBtnScan] = useState(true)

  const scanNow = async (isScan) => {
    setBtnScan(isScan)
    if (isScan) stopScan = true
    if (btnScan === false) return
    stopScan = false
    await new Promise(r => setTimeout(r, 100))
    const videoElement = document.getElementById('scanView')
    const scanner = new QrScanner(
      videoElement,
      result => {
        setCode(result.data)
        hasilScan = result.data
        setBtnScan(true)
        stopScan = true
      },
      {
        onDecodeError: error => {
          console.error(error)
        },
        maxScansPerSecond: 1,
        highlightScanRegion: true,
        highlightCodeOutline: true,
        returnDetailedScanResult: true
      }
    )
    await scanner.start()
    while (stopScan === false) await new Promise(r => setTimeout(r, 100))
    scanner.stop()
    scanner.destroy()
  }
  const delay = 500;

  const previewStyle = {
    height: 240,
    width: 320,
  };


  useEffect(() => setRef(code), [payload])
  useEffect(() => { 
    const queryParameters = new URLSearchParams(window.location.search)
    setCodeUrl(queryParameters.get("code"));
   }, [])

  useEffect(() => {
    setCode(codeurl);
  }, [codeurl])

  const verif = () => {

    const requestOptions = {
      referrerPolicy: "unsafe-url",
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    var url = 'https://backapi.ktc-racing.com:8088/verify'
    // var url = 'http://localhost:8088/verify'
    url += '?code=' + code

    fetch(url, requestOptions).then(response => response.json()).then(data => {
      console.log(data);
      if (data == undefined) { setPayload("Null") } else { setPayload(data[0]); setBDis(true) }
    })
  }

  const InfoBox = () => {
    if (payload == "Null") {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{
            width: '50%',
            marginLeft: '25%',
            textAlign: 'center',
            alignContent: 'center',
            justifyItems: 'center',
            justifyContent: 'center',
            border: '2px solid red',
            marginTop: '3%',
            borderRadius: 4,
            color: '#000'
          }}> <br></br><center><img src={fail} width={40} height={40}></img></center><br></br>
            <b>Nomor yang anda masukan tidak ditemukan!<br></br>
              Cek kembali nomor verifikasi anda atau hubungi penjual untuk meminta pertanggung jawaban.<br></br>
              Kirim pengaduan ke: 0812 1919 6968 (WA)</b><br></br></div>
        </div >

      )
    }
    else {
      if (payload.scanned == 0) {
        if (payload.fraud == 1) {
          return (
            <div style={{ display: 'flex' }}>
              <div style={{
                width: '50%',
                marginLeft: '25%',
                textAlign: 'center',
                alignContent: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                border: '2px solid red',
                marginTop: '3%',
                borderRadius: 4,
                color: '#000'
              }}> <br></br><center><img src={fake} width={40} height={40}></img></center><br></br>
                <div style={{ color: 'red' }}>
                  <b>Nomor yang anda masukan digunakan oleh barang PALSU<br></br>
                    Mintalah pertanggung jawaban dengan penjual<br></br>
                    Adukan pemalsuan ke: 0812 1919 6968 (WA)<br></br></b>
                  &nbsp;</div></div>
            </div >
          )

        }
        return (
          <div style={{ display: 'flex' }}>
            <div style={{
              width: '50%',
              marginLeft: '25%',
              textAlign: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              justifyContent: 'center',
              border: '2px solid green',
              marginTop: '3%',
              borderRadius: 4,
              color: '#000'
            }}> <br></br><center><img src={ok} width={40} height={40}></img></center><br></br>
              <div style={{ color: 'green' }}>


                <b>  Nomor yang anda masukan berhasil diverifikasi!<br></br>
                  Terima kasih telah menggunakan produk asli KTC Racing.<br></br>
                  Produk: {payload.tipe}<br></br></b>
                &nbsp;</div></div>
          </div >
        )
      }
      if (payload.scanned > 0) {
        if (payload.fraud == 1) {
          return (
            <div style={{ display: 'flex' }}>
              <div style={{
                width: '50%',
                marginLeft: '25%',
                textAlign: 'center',
                alignContent: 'center',
                justifyItems: 'center',
                justifyContent: 'center',
                border: '2px solid red',
                marginTop: '3%',
                borderRadius: 4,
                color: '#000'
              }}> <br></br><center><img src={fake} width={40} height={40}></img></center><br></br>
                <div style={{ color: 'red' }}>
                  <b>Nomor yang anda masukan digunakan oleh barang PALSU<br></br>
                    Mintalah pertanggung jawaban dengan penjual<br></br>
                    Adukan pemalsuan ke: 0812 1919 6968 (WA)<br></br></b>
                  &nbsp;</div></div>
            </div >
          )

        }
        return (
          <div style={{ display: 'flex' }}>
            <div style={{
              width: '50%',
              marginLeft: '25%',
              textAlign: 'center',
              alignContent: 'center',
              justifyItems: 'center',
              justifyContent: 'center',
              border: '2px solid yellow',
              marginTop: '3%',
              borderRadius: 4,
              color: '#000'
            }}> <br></br><center><img src={warning} width={40} height={40}></img></center><br></br>
              <div style={{ color: 'black' }}>
                <b>Nomor yang anda masukan sudah pernah diverifikasi sebelumnya<br></br>
                  Bila anda membeli produk dengan keadaan baru, silahkan hubungi penjual. <br />

                  Produk: {payload.tipe}<br></br></b>
                &nbsp;</div></div>
          </div >
        )
      }
      return (<div></div>)
    }
  }

  const Scanner = () => {
    if (btnScan === false) {
      return (<Box sx={{
        height: '20vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {btnScan === false && <video id="scanView" style={{
          width: '100%',
          maxWidth: '400px',
          height: '100%',
          maxHeight: '400px',
          borderStyle: 'dotted',
        }}>
        </video>}

      </Box>)
    } else {
      <div></div>
    }
  }
  return (
    <ChakraProvider theme={theme}>

      <div className="App">
        <div>
          <BrowserView>
            <header style={{
              backgroundImage: `url(${banner})`,
              minHeight: '38vh',
              width: '100%',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            }} >
            </header>
          </BrowserView>
          <MobileView>
            <header style={{
              backgroundImage: `url(${bannermob})`,
              minHeight: '25vh',
              width: '100%',
              backgroundSize: '100%',
              backgroundRepeat: 'no-repeat',
            }} >
            </header>
          </MobileView>
        </div>
        <BrowserView>
          <div className="txtHead"><b>--Pastikan keaslian produk KTC Racing anda dengan <br />verifikasi kode produk yang tercantum.</b> </div>
          <div className="txtHead"><b>Ikuti langkah yang ada di kartu / stiker verifikasi anda</b> </div>
        </BrowserView>
        <MobileView>
          <div className="txtHeadMob"><b>^^Pastikan keaslian produk KTC Racing anda dengan verifikasi kode produk yang tercantum.</b> </div>
          <div className="txtHeadMob"><b>Ikuti langkah yang ada di kartu / stiker verifikasi anda</b> </div>
        </MobileView>
        <br></br>
        <br />

        <Scanner />


        <Input placeholder='Masukan kode verifikasi anda' onChange={handleCode} value={code} size='lg' width='auto' htmlSize={26} />
        {/* <Button style={{ height: 50 }} onClick={() => startQrCode()}>
          <Icon color='red.500'>
            <path
              fill='#000000'
              d='M9 6h-1v-1h2v2h-1v-1zm2-1h-1v-2h-2v-1h3v1h1v1h-1v1zm2-3h-2v-1h-3v-1h4v1h1v-1h1v5h-1v-3zm-8 3h-3v-3h3v3zm7 0h1v1h-1v-1zm8 0h-3v-3h3v3zm2-5h-7v7h7v-7zm-1 6h-5v-5h5v5zm-10 0h1v1h-1v-1zm2 1v-1h1v1h-1zm-7 2v1h-1v-1h1zm1-9h-7v7h7v-7zm-1 6h-5v-5h5v5zm3 8v2h-1v-2h1zm-4-6v1h-2v1h1v1h-3v-1h1v-1h-2v-1h5zm-3 9h3v3h-3v-3zm-2 5h7v-7h-7v7zm1-6h5v5h-5v-5zm5-3h1v1h-1v-1zm-2-1v-1h1v3h-1v-1h-1v1h-1v-1h-1v1h-1v-2h4zm6.089 4.734c0-3.765 3.149-6.845 6.915-6.725 1.954.052 3.761.944 4.996 2.429v-2.4379999999999997h-1v-2h-1v1.69c-.627-.284-1.298-.483-2-.591v-.099h1v-1h-2v1h-1v-1h-1v1h-1v-1h-1v1h-1v-1h-1v1h-1v1h-1v-1h1v-1h-4v1h2v1h-2v1h1v2h1v-1h2v1.1c-.471.88-.775 1.86-.874 2.9h-.126v1h-1v5h1v-1h1.383c.239.356.495.674.791 1h1.473c-1.677-1.334-2.558-3.295-2.558-5.266zm.911-5.734v-1h1v.698c-.362.29-.695.613-1 .962v-.66zm-2 9v-1h.429c.106.345.242.677.393 1h-.822zm15 2.586l-2.832-2.832c.524-.79.832-1.735.832-2.754 0-2.761-2.239-5-5-5s-5 2.239-5 5 2.239 5 5 5c1.019 0 1.964-.308 2.754-.832l2.832 2.832 1.414-1.414zm-7-2.586c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3z'
            />
          </Icon>
        </Button>   */}
        <Button onClick={() => scanNow(!btnScan)} style={{ height: 50 }} >
          {btnScan && <QrCodeScanner />}
          {btnScan === false && <Stop />}
        </Button >
        <br></br>        <br></br>

        <Button colorScheme="brand" size='lg' onClick={() => verif()} disabled={bdis}>
          <b>Verifikasi</b>
        </Button>
        <br></br>
        <a href="http://ktc-racing.com" className='App-link'><b><u>HOME</u></b></a>
        <InfoBox></InfoBox>
        <br></br><br></br>
      </div >
    </ChakraProvider>
  );
}

export default App;
